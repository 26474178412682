import { useField } from "formik";
import { DateTimePickerProps } from "@mui/x-date-pickers/DateTimePicker";
import { useEffect } from "react";
import { useAppSelector } from "commons/store/hooks";
import { selectEcrf } from "features/ecrf/redux/ecrfSlice";
import { isValid, parseISO } from "date-fns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export interface DateTimePickerFormikProps
    extends Omit<DateTimePickerProps<any>, "name" | "value" | "onChange" | "onBlur" | "error"> {
    name: string;
    status?: boolean;
    data?: any;
    fullWidth?: boolean;
    helperText?: any;
    placeholder?: string;
    required?: boolean;
}

const DateTimePickerFormik = ({
  data,
  name,
  fullWidth,
  helperText,
  status,
  required,
  ...props
}: DateTimePickerFormikProps) => {
    const [field, meta, form] = useField(name);
    const ecrf = useAppSelector(selectEcrf);

    const { value } = meta;
    const { setValue } = form;

    /**
     * The 'referenceDate' argument is used to provide values for date parts that are not provided
     * by the 'value' argument. referenceDate will substitute zero for hours, minutes and seconds.
     * @see https://date-fns.org/v2.29.1/docs/parse
     */
    const dateFnsParse = parseISO(field.value);

    useEffect(() => {
        // @todo rebuild this
        if ((value && data) && value !== data[0]) {
            if (data.length === 0) {
                setValue('')
            }

            if (data.length > 0) {
                setValue(data[0])
            }
        }

    }, [ecrf, data])

    return (
        <DateTimePicker
            label={field.name}
            disabled={status}
            defaultValue={isValid(dateFnsParse || value) ? dateFnsParse || value : null}
            onChange={(newValue) => setValue(newValue)}
            onClose={() => form.setTouched(true)}
            slotProps={{
                textField: {
                    error: meta.touched && Boolean(meta.error),
                    fullWidth: fullWidth,
                    helperText: (meta.touched && meta.error) || helperText,
                    variant: 'outlined',
                    required: required
                }
            }}
            {...props}
        />
    );
};

export default DateTimePickerFormik;

import { roles } from "../../commons/auth/roles";
import DateTime from "./date/DateTime";
import {DATETIME_FORMAT} from "../../commons/constants/env";
import {Box} from "@mui/material";
import React from "react";
import {invert} from "lodash";

export default class Element {
  /**
   * Element is a collection type
   */
  public static isCollection(type: string) {
    switch (type) {
      case 'adverse_event':
      case 'collection':
      case 'data_grid':
      case 'table': {
        return true;
      }
    }

    return false;
  }

  /**
   * Element is a date type
   */
  public static isDate(type: string) {
    switch (type) {
      case 'date':
      case 'datetime':
      case 'time': {
        return true;
      }
    }

    return false;
  }

  /**
   * Element is a functional type
   */
  public static isFunctional(type: string) {
    switch (type) {
      case 'dicom_button':
      case 'close_observation_period':
      case 'randomization': {
        return true;
      }
    }

    return false;
  }

  /**
   * Element actions access
   */
  public static actionsAccess(actions: any, userRoles: any) {
    if (actions.includes('nd') && !userRoles.some(
        (role: string) => roles.researcher.includes(role) || roles.admin.includes(role))
    ) {
      return actions.filter((action: any) => action !== 'nd');
    }

    return actions;
  }

  /**
   * Element value format
   */
  public static valueFormat(value: any): any {
    return Array.isArray(value) ? value : [value];
  }

  /**
   * Element data format
   */
  public static dataFormat(elementData: any): any {
    let value = Array.isArray(elementData?.data)
        ? elementData?.data.map((el: string) => el)
        : elementData?.data[0];

    switch (elementData?.element?.type) {
      case 'association':
      case 'checkbox_multiple':
      case 'choice':
      case 'select':
      case 'select_multiple':
      case 'radio': {
        let choices = elementData?.element?.options?.choices || elementData?._embedded?.element?.options?.choices;
        choices = Array.isArray(choices) ? choices : invert(choices);

        if (choices) {
          value = Array.isArray(elementData?.data)
              ? elementData?.data.map((el: string) => choices[el])
              : choices[elementData?.data[0]];
        }
        break;
      }
      case 'date':
      case 'datetime':
      case 'time': {
        if (
            DateTime.isDateTime(elementData?.data[0]) &&
            DateTime.toIso(elementData?.data[0])
        ) {
          value = DateTime.toIso(
              elementData?.data[0],
              (
                  elementData?.element?.options?.format ||
                  elementData?._embedded?.element?.options?.format
              )
              ?? DATETIME_FORMAT
          );
        }
        break;
      }
    }

    return value;
  }
}

import { useField } from "formik";
import { TimePicker, TimePickerProps } from "@mui/x-date-pickers";
import React, {useEffect, useState} from "react";
import {isValid, parseISO} from 'date-fns';
import {TIME_FORMAT} from "../../commons/constants/env";

export interface TimePickerFormikProps
    extends Omit<TimePickerProps<any>, "name" | "value" | "onChange" | "onBlur" | "error"> {
    name: string;
    status?: boolean;
    fullWidth?: boolean;
    helperText?: any;
    placeholder?: string;
    required?: boolean;
}

const TimePickerFormik = ({
  name,
  status,
  fullWidth,
  helperText,
  placeholder,
  required,
  ...props
}: TimePickerFormikProps) => {
    const [field, meta, form] = useField(name);

    const { value } = meta;
    const { setValue } = form;

    /**
     * The 'referenceDate' argument is used to provide values for date parts that are not provided
     * by the 'value' argument. referenceDate will substitute zero for hours, minutes and seconds.
     * @see https://date-fns.org/v2.29.1/docs/parse
     */
    const dateFnsParse = parseISO(value);

    return (
        <TimePicker
            ampm={false}
            label={field.name}
            disabled={status}
            defaultValue={isValid(dateFnsParse || value) ? dateFnsParse || value : null}
            onChange={(newValue: any) => setValue(newValue)}
            onClose={() => form.setTouched(true)}
            format={props?.format || TIME_FORMAT}
            slotProps={{
                textField: {
                    error: meta.touched && Boolean(meta.error),
                    fullWidth: fullWidth,
                    helperText: (meta.touched && meta.error) || helperText,
                    placeholder: placeholder,
                    variant: 'outlined',
                    required: required
                }
            }}
            {...props}
        />
    );
};

export default TimePickerFormik;

import React from "react";
import { useField } from "formik";
import Chip, { ChipProps } from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import { ArrowCircleUpOutlined, ArrowCircleDownOutlined, CheckCircleOutlineOutlined } from '@mui/icons-material';
import { isArray } from "lodash";
import { useTranslation } from "react-i18next";

export interface RangeIndicatorFormikProps
  extends Omit<ChipProps, "name" | "onChange" | "onBlur" | "error"> {
  name: string;
  helperText?: string;
  options: Array<{ key: string; value: string }>;
  status?: boolean;
}

const RangeIndicatorFormik = ({
  name,
  helperText,
  options,
  status,
  ...props
}: RangeIndicatorFormikProps) => {
  const [field, meta, form] = useField(name);
  const { t } = useTranslation();

  const renderElement = () => {
    switch (isArray(field.value) ? field.value[0] : field.value) {
      case -1: {
        return <Chip label={t('low')} variant="outlined" color="error" icon={<ArrowCircleDownOutlined />} />
      }
      case 0: {
        return <Chip label={t('norm')} variant="outlined" color="success" icon={<CheckCircleOutlineOutlined />} />;
      }
      case 1: {
        return <Chip label={t('high')} variant="outlined" color="error" icon={<ArrowCircleUpOutlined />} />;
      }
      default:
        return null;
    }
  }

  return (
    <>
      {renderElement()}
      <FormHelperText>
        {(meta.touched && meta.error) || helperText}
      </FormHelperText>
    </>
  );
};

export default RangeIndicatorFormik;
